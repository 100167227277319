import { Button, TextField, Alert } from "@mui/material";
import { useState, useEffect } from "react";
import "./Auth.css";
import LogoBar from "./components/LogoBar";
import axios from "./utils/axios";

const Auth = () => {
  const [logInEmail, setLogInEmail] = useState("");
  const [logInPassword, setLogInPassword] = useState("");
  const [signUpFirstName, setSignUpFirstName] = useState("");
  const [signUpLastName, setSignUpLastName] = useState("");
  const [signUpEmail, setSignUpEmail] = useState("");
  const [signUpPassword, setSignUpPassword] = useState("");
  const [alertMsgError, setAlertMsgError] = useState(null);
  const [alertMsgSuccess, setAlertMsgSuccess] = useState(null);

  useEffect(() => {
    axios
      .get("/auth/isLoggedIn")
      .then((data) => {
        const isLoggedIn = data.data.msg.isLoggedIn;

        if (isLoggedIn) {
          window.open("/dashboard", "_self");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err) setAlertMsgError("Internal server error.");
      });
  }, []);

  return (
    <div className="body">
      {alertMsgError ? <Alert severity="error">{alertMsgError}</Alert> : <></>}
      {alertMsgSuccess ? (
        <Alert severity="success">{alertMsgSuccess}</Alert>
      ) : (
        <></>
      )}
      <LogoBar color="black" />
      <div className="formContainer">
        <div>
          <h1 className="LogInHeader">Log In</h1>
          <form className="LogInForm">
            <TextField
              label="Email"
              variant="outlined"
              style={{ marginBottom: 30 }}
              value={logInEmail}
              onChange={(e) => setLogInEmail(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              style={{ marginBottom: 30 }}
              value={logInPassword}
              onChange={(e) => setLogInPassword(e.target.value)}
            />
            <Button
              className="logInButton"
              variant="contained"
              type="submit"
              style={{ backgroundColor: "#8c1515" }}
              onClick={(e) => {
                e.preventDefault();
                if (logInEmail.length < 3 || !logInEmail.includes("@")) {
                  setAlertMsgSuccess("");
                  setAlertMsgError("Invalid email.");
                } else if (logInPassword.length < 8) {
                  setAlertMsgSuccess("");
                  setAlertMsgError(
                    "Password must be at least 8 characters long."
                  );
                } else {
                  axios
                    .post("/auth/login", {
                      email: logInEmail,
                      password: logInPassword,
                    })
                    .then((res) => {
                      setLogInEmail("");
                      setLogInPassword("");
                      setAlertMsgSuccess(res.data.msg);
                      window.open("/dashboard", "_self");
                    })
                    .catch((err) => {
                      setLogInEmail("");
                      setLogInPassword("");
                    });
                }
              }}
            >
              Log In
            </Button>
          </form>
        </div>
        <div className="rowLine" />
        <div>
          <h1 className="SignUpHeader">Sign Up</h1>
          <form className="SignUpForm">
            <TextField
              label="First Name"
              variant="outlined"
              style={{ marginBottom: 30 }}
              value={signUpFirstName}
              onChange={(e) => setSignUpFirstName(e.target.value)}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              style={{ marginBottom: 30 }}
              value={signUpLastName}
              onChange={(e) => setSignUpLastName(e.target.value)}
            />
            <TextField
              label="Email"
              variant="outlined"
              style={{ marginBottom: 30 }}
              value={signUpEmail}
              onChange={(e) => setSignUpEmail(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              style={{ marginBottom: 30 }}
              value={signUpPassword}
              onChange={(e) => setSignUpPassword(e.target.value)}
            />
            <Button
              className="signUpButton"
              variant="contained"
              type="submit"
              style={{ backgroundColor: "#8c1515" }}
              onClick={(e) => {
                e.preventDefault();
                if (signUpEmail.length < 3 || !signUpEmail.includes("@")) {
                  setAlertMsgSuccess("");
                  setAlertMsgError("Invalid email.");
                } else if (
                  signUpFirstName.length < 2 ||
                  signUpLastName.length < 2
                ) {
                  setAlertMsgSuccess("");
                  setAlertMsgError(
                    "First and last name must be at least 2 characters each."
                  );
                } else if (signUpPassword.length < 8) {
                  setAlertMsgSuccess("");
                  setAlertMsgError(
                    "Password must be at least 8 characters long."
                  );
                } else {
                  axios
                    .post("/auth/signup", {
                      firstName: signUpFirstName,
                      lastName: signUpLastName,
                      email: signUpEmail,
                      password: signUpPassword,
                    })
                    .then((res) => {
                      setAlertMsgError("");
                      setAlertMsgSuccess(res.data.msg);
                      setSignUpEmail("");
                      setSignUpFirstName("");
                      setSignUpLastName("");
                      setSignUpPassword("");
                    })
                    .catch((err) => {
                      setAlertMsgSuccess("");
                      setAlertMsgError(err.response.data.msg);
                      setSignUpEmail("");
                      setSignUpFirstName("");
                      setSignUpLastName("");
                      setSignUpPassword("");
                    });
                }
              }}
            >
              Sign Up
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Auth;
