// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  text-align: center;
}

.header {
  font-family: "Playfair Display";
  font-weight: 400;
  color: white;
  margin-left: 10px;
}

.info {
  color: white;
  font-size: 64px;
  font-weight: 150;
  font-family: "DM Sans";
  position: relative;
  z-index: 10;
}

.textContainer {
  width: 80%;
  margin: 0 auto;
  text-align: left !important;
}

.earthImage {
  margin-top: -275px;
  position: relative;
  z-index: 1;
}

.tryAlphaBtn {
  background-color: white;
  height: 20px;
  padding: 30px;
  padding-right: 120px;
  z-index: 100;
  position: relative;
  top: -100px;
  color: black;
  font-family: "DM Sans";
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border: 1px solid white;
}

.tryAlphaBtn:hover {
  background-color: rgba(0, 0, 0, 0);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tryAlphaBtn:hover > .arrow {
  color: white;
}

.arrow {
  margin-left: 10px;
  align-self: center;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,oBAAoB;EACpB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,kCAAkC;EAClC,YAAY;EACZ,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".App {\n  background-color: black;\n  height: 100vh;\n  width: 100vw;\n  overflow-x: hidden;\n  text-align: center;\n}\n\n.header {\n  font-family: \"Playfair Display\";\n  font-weight: 400;\n  color: white;\n  margin-left: 10px;\n}\n\n.info {\n  color: white;\n  font-size: 64px;\n  font-weight: 150;\n  font-family: \"DM Sans\";\n  position: relative;\n  z-index: 10;\n}\n\n.textContainer {\n  width: 80%;\n  margin: 0 auto;\n  text-align: left !important;\n}\n\n.earthImage {\n  margin-top: -275px;\n  position: relative;\n  z-index: 1;\n}\n\n.tryAlphaBtn {\n  background-color: white;\n  height: 20px;\n  padding: 30px;\n  padding-right: 120px;\n  z-index: 100;\n  position: relative;\n  top: -100px;\n  color: black;\n  font-family: \"DM Sans\";\n  font-weight: 300;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 24px;\n  border: 1px solid white;\n}\n\n.tryAlphaBtn:hover {\n  background-color: rgba(0, 0, 0, 0);\n  color: white;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.tryAlphaBtn:hover > .arrow {\n  color: white;\n}\n\n.arrow {\n  margin-left: 10px;\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
