// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.caseStudyIconContainer {
  height: 220px;
  width: 220px;
  padding: 20px;
  border-radius: 100%;
  border: 1px solid gray;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  background-color: #2c2c29;
}

.caseStudyIcon {
  font-size: 120px;
}

.caseStudyHeader {
  margin-top: 140px;
  font-family: "Playfair Display";
  font-weight: 200;
  font-size: 24px;
  margin-top: 10px;
  color: black;
}

.caseStudy {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.caseStudyProgressContainer {
  width: 100px;
  height: 10px;
  border-radius: 10px;
  background-color: #42423d;
  margin-top: 20px;
  border: 1px solid #42423d;
}

.caseStudyIconContainer:hover {
  cursor: pointer;
  background-color: #42423d;
}

.caseStudyProgress {
  background-color: black;
  height: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.resume {
  font-weight: 200;
  width: 120px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard-CaseStudyIcon.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".caseStudyIconContainer {\n  height: 220px;\n  width: 220px;\n  padding: 20px;\n  border-radius: 100%;\n  border: 1px solid gray;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  display: flex;\n  flex-direction: row;\n  background-color: #2c2c29;\n}\n\n.caseStudyIcon {\n  font-size: 120px;\n}\n\n.caseStudyHeader {\n  margin-top: 140px;\n  font-family: \"Playfair Display\";\n  font-weight: 200;\n  font-size: 24px;\n  margin-top: 10px;\n  color: black;\n}\n\n.caseStudy {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.caseStudyProgressContainer {\n  width: 100px;\n  height: 10px;\n  border-radius: 10px;\n  background-color: #42423d;\n  margin-top: 20px;\n  border: 1px solid #42423d;\n}\n\n.caseStudyIconContainer:hover {\n  cursor: pointer;\n  background-color: #42423d;\n}\n\n.caseStudyProgress {\n  background-color: black;\n  height: 10px;\n  border-top-left-radius: 10px;\n  border-bottom-left-radius: 10px;\n}\n\n.resume {\n  font-weight: 200;\n  width: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
