import React, { useContext, useLayoutEffect, useState } from "react";
import { globalContext } from "../context";
import "./Dashboard.css";
import DashboardCaseStudyIcon from "../components/Dashboard-CaseStudyIcon";
import axios from "../utils/axios";
import { CircularProgress } from "@mui/material";
import LogoBar from "../components/LogoBar";
const Dashboard = () => {
  const { user, setUser } = useContext(globalContext);
  const [allCaseStudies, setAllCaseStudies] = useState([]);
  const [userCaseStudies, setUserCaseStudies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    axios
      .get("/auth/isLoggedIn", { withCredentials: true })
      .then((res) => {
        console.log(res.data.msg);
        if (!res.data.msg.isLoggedIn) {
          window.open("/auth", "_self");
        } else {
          setUser(res.data.msg.user);
          axios
            .get("/getCaseStudiesForDashboard")
            .then((allCaseStudyRes) => {
              setAllCaseStudies(allCaseStudyRes.data.msg);
              axios
                .get("/userCaseStudyChats")
                .then((userCaseStudyRes) => {
                  const res_user_case_studies = userCaseStudyRes.data.msg;
                  setUserCaseStudies(res_user_case_studies);
                  setIsLoading(false);
                })
                .catch((userCaseStudyErr) => {
                  alert(userCaseStudyErr.response.data.msg);
                });
            })
            .catch((allCaseStudyErr) => {
              alert(allCaseStudyErr.response.data.msg);
            });
        }
      })
      .catch((error) => {
        window.open(`${error}.com`, "_self");
      });
  }, []);

  const getProgressPercentage = (userCaseStudies, caseStudy) => {
    if (userCaseStudies.length == 0) return 0;
    else {
      const filtration_results = userCaseStudies.filter(
        (userCaseStudy) => userCaseStudy.case_study_id == caseStudy.id
      );
      if (filtration_results.length == 0) return 0;
      else {
        if (filtration_results[0].case_study_current_q == -1) {
          return 1;
        }
        return (
          filtration_results[0].case_study_current_q /
          (caseStudy.questions.length + 1)
        );
      }
    }
  };

  if (user.firstName && !isLoading) {
    return (
      <div className="pageContainer">
        <LogoBar fontSize={18} iconSize={21} color={"black"} />
        <div className="welcomeBanner">
          <h1 className="welcomeHeader">Welcome, {user.firstName}.</h1>
          <div className="acctButtons">
            <a href="/community">
              <button>Community</button>
            </a>
            <a href="/account">
              <button>Account</button>
            </a>
          </div>
        </div>
        <div className="caseStudiesBanner">
          <p className="caseStudiesHeader">Interactive case studies</p>
          <p className="caseStudiesSource">
            Sourced from{" "}
            <a
              className="caseStudiesSourceLink"
              target="_blank"
              href="https://litfl.com/"
            >
              LITFL
            </a>{" "}
            under a{" "}
            <a
              href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
              className="caseStudiesCcLink"
              target="_blank"
            >
              Creative Commons Attribution-NonCommercial-ShareAlike 4.0
              International License
            </a>
          </p>
        </div>
        <div className="caseStudiesContent">
          {allCaseStudies.map((caseStudy, caseStudyIndex) => {
            const progressPercentage = getProgressPercentage(
              userCaseStudies,
              caseStudy
            );
            return (
              <DashboardCaseStudyIcon
                key={caseStudyIndex}
                headerText={caseStudy["name"]}
                progressPercentage={progressPercentage * 100}
                caseStudy={caseStudy}
                setIsLoading={setIsLoading}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className="loadingContainer">
        <CircularProgress style={{ color: "#8c1515", alignSelf: "center" }} />
        <p className="loadingText">Loading...</p>
      </div>
    );
  }
};

export default Dashboard;
