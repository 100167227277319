// import * as axios_lib from "axios";
import axios from "axios";

const instance = axios.create({
  baseURL: "https://backend.apolloglobalhealth.org", // IMPORTANT CODE
  // baseURL: "http://localhost:5000",
  // baseURL: "http://localhost:10021",
  withCredentials: true,
});

export default instance;
