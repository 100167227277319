import logo from "../images/logo.png";
import "./LogoBar.css";
const LogoBar = (props) => {
  return (
    <div
      style={{
        ...props.styles,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="topBanner"
      onClick={() => window.open(props.url || "/", "_self")}
    >
      {props.showIcon == false ? (
        <></>
      ) : (
        <img
          src={logo}
          alt="Logo"
          height={props.iconSize || 40}
          width={props.iconSize || 40}
        />
      )}
      <h1
        className="header"
        style={{
          color: props.color || "white",
          fontSize: props.fontSize || 32,
        }}
      >
        Apollo Global Health
      </h1>
    </div>
  );
};

export default LogoBar;
