import { createContext, useState } from "react";

export const globalContext = createContext(null);

function GlobalContextComponent({ children }) {
  const [user, setUser] = useState({});
  return (
    <globalContext.Provider value={{ user, setUser }}>
      {children}
    </globalContext.Provider>
  );
}

export default GlobalContextComponent;
