const Error404 = () => {
  return (
    <div>
      <h1
        style={{
          alignSelf: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        404 | Page not found.
      </h1>
    </div>
  );
};

export default Error404;
