// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadingText {
  font-size: 24px;
  font-weight: 300;
  color: black;
}

.loadingContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pageContainer {
  background-color: white;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.profileContainer {
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 100px;
  position: absolute;
  left: 5vw;
  top: 5vh;
  justify-content: space-between;
  align-items: left;
}

.profileHeader {
  font-family: "Playfair Display";
  font-weight: 300;
  font-size: 52px;
  color: black;
}

input {
  width: 400px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #cdcdcd;
  resize: none;
  justify-content: center;
  padding: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  overflow-y: none;
  outline: none;
}

input {
  border: 1px solid red;
}
`, "",{"version":3,"sources":["webpack://./src/authed-pages/Account.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb;wEACsE;EACtE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".loadingText {\n  font-size: 24px;\n  font-weight: 300;\n  color: black;\n}\n\n.loadingContainer {\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.pageContainer {\n  background-color: white;\n  width: 100vw;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n}\n\n.profileContainer {\n  display: flex;\n  flex-direction: column;\n  width: 90vw;\n  height: 100px;\n  position: absolute;\n  left: 5vw;\n  top: 5vh;\n  justify-content: space-between;\n  align-items: left;\n}\n\n.profileHeader {\n  font-family: \"Playfair Display\";\n  font-weight: 300;\n  font-size: 52px;\n  color: black;\n}\n\ninput {\n  width: 400px;\n  height: 20px;\n  border-radius: 10px;\n  border: 1px solid #cdcdcd;\n  resize: none;\n  justify-content: center;\n  padding: 20px;\n  font-family: system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto,\n    Oxygen, Ubuntu, Cantarell, \"Open Sans\", \"Helvetica Neue\", sans-serif;\n  overflow-y: none;\n  outline: none;\n}\n\ninput {\n  border: 1px solid red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
