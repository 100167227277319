import { useEffect, useState } from "react";
import "./Account.css";
import axios from "../utils/axios";
import { CircularProgress, TextField } from "@mui/material";
import LogoBar from "../components/LogoBar";

const Account = () => {
  const [user, setUser] = useState();
  useEffect(() => {
    axios
      .get("/auth/isLoggedIn")
      .then((res) => {
        if (!res.data.msg.isLoggedIn) window.open("/auth");
        setUser(res.data.msg.user);
      }, [])
      .catch((err) => {
        alert(err.response.data.msg);
        window.open("/auth");
      });
  }, []);
  if (user != null)
    return (
      <div className="pageContainer">
        <LogoBar fontSize={18} iconSize={21} url="/dashboard" color="black" />
        <div className="profileContainer">
          <h1 className="profileHeader">Profile</h1>
          <p className="firstNameLabel">First Name</p>
          <input
            className="firstNameInput"
            value={user.firstName}
            label="First Name"
            onChange={(e) => {
              setUser({ ...user, firstName: e.target.value });
            }}
          />
          <input
            className="lastNameInput"
            value={user.lastName}
            onChange={(e) => {
              setUser({ ...user, lastName: e.target.value });
            }}
          />
        </div>
      </div>
    );
  else
    return (
      <div className="loadingContainer">
        <CircularProgress style={{ color: "#8c1515", alignSelf: "center" }} />
        <p className="loadingText">Loading account information...</p>
      </div>
    );
};

export default Account;
