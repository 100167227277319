import "./App.css";
import earth from "./images/earth.jpg";
import LogoBar from "./components/LogoBar";
import axios from "./utils/axios";

function App() {
  return (
    <div className="App">
      <div className="textContainer">
        <LogoBar color="white" />
        <h6 className="info">
          We are revolutionizing global health education.
        </h6>
        <button
          className="tryAlphaBtn"
          onClick={() => {
            axios
              .get("/auth/isLoggedIn")
              .then((res) => {
                const isLoggedIn = res.data.msg.isLoggedIn;
                if (isLoggedIn) {
                  window.open("/dashboard", "_self");
                } else {
                  window.open("/auth", "_self");
                }
              })
              .catch((err) => {
                window.open("/auth", "_self");
              });
          }}
        >
          Try v0.1 alpha
          <svg
            xmlns="http://www.w3.org/2000/svg"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            imageRendering="optimizeQuality"
            fillRule="evenodd"
            clipRule="evenodd"
            viewBox="0 0 512 512"
            height={18}
            fill="currentColor"
            className="arrow"
          >
            <path
              fillRule="nonzero"
              d="M165.43.04c-8.92-.6-16.56 6.15-17.06 15.06s6.33 16.62 15.24 17.21l274.42 18.58L4.7 484.23c-6.31 6.3-6.26 16.59.11 22.96 6.38 6.37 16.66 6.42 22.96.12L461.11 73.97l18.58 274.42c.59 8.91 8.3 15.74 17.21 15.24s15.66-8.14 15.06-17.05L490.98 36.62c-.35-8.2-6.88-15.01-15.25-15.57L165.43.04z"
            />
          </svg>
        </button>
      </div>
      <img
        src={earth}
        height={1024}
        className="earthImage"
        alt={"Image of the earth"}
        draggable="false"
      />
    </div>
  );
}

export default App;
