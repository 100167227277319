import React from "react";
import "./Dashboard-CaseStudyIcon.css";
import axios from "../utils/axios";

const ICON_EMOJI_MAPPING = {
  pulmonary: "🫁",
};

const DashboardCaseStudyIcon = ({
  headerText,
  progressPercentage,
  caseStudy,
  setIsLoading,
}) => {
  return (
    <div className="caseStudy">
      <div className="caseStudyIconContainer">
        <div className="caseStudyIcon">
          {ICON_EMOJI_MAPPING[headerText.split(" ")[0].toLowerCase()]}
        </div>
      </div>
      <div className="caseStudyProgressContainer">
        <div
          className="caseStudyProgress"
          style={{
            width: `${progressPercentage}%`,
            backgroundColor: progressPercentage == 100 ? "#32CD32" : "white",
            borderTopRightRadius: progressPercentage == 100 ? "10px" : "0px",
            borderBottomRightRadius: progressPercentage == 100 ? "10px" : "0px",
          }}
        />
      </div>
      <p className="caseStudyHeader">{headerText}</p>
      <button
        className={"resume"}
        onClick={() => {
          setIsLoading(true);
          if (progressPercentage == 0) {
            axios
              .post("/chatCaseStudy", {
                case_study_id: caseStudy.id,
                new_message: "",
              })
              .then(() => {
                window.open(`/chat/${caseStudy.id}`, "_self");
              })
              .catch((err) => alert(err.response.data.msg));
          } else {
            window.open(`/chat/${caseStudy.id}`, "_self");
          }
        }}
      >
        {progressPercentage == 0
          ? "Start"
          : progressPercentage == 100
          ? "View"
          : "Resume"}
      </button>
    </div>
  );
};

export default DashboardCaseStudyIcon;
