// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#notShownNotesButton {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2.5vw;
  display: inline-flex;
  color: #222222;
}

#notShownNotesButton:hover {
  cursor: pointer;
}

#shownNotesButton {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 7%;
}
`, "",{"version":3,"sources":["webpack://./src/utils/NotesButton.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;EACZ,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,QAAQ;AACV","sourcesContent":["#notShownNotesButton {\n  background-color: rgba(0, 0, 0, 0);\n  border: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  right: 2.5vw;\n  display: inline-flex;\n  color: #222222;\n}\n\n#notShownNotesButton:hover {\n  cursor: pointer;\n}\n\n#shownNotesButton {\n  display: flex;\n  flex-direction: row;\n  position: absolute;\n  left: 7%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
